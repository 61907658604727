import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useToast,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import { FormInputSelectedAktif } from "../../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"

const FormKategoriPerjal = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										nama_kategori_perjal:
											row.nama_kategori_perjal,
										status_kategori_perjal:
											row.status_kategori_perjal,
								  }
								: {
										nama_kategori_perjal: "",
										status_kategori_perjal: "",
								  }
						}
						validationSchema={Yup.object({
							nama_kategori_perjal:
								Yup.string().required("Required"),
							status_kategori_perjal:
								Yup.string().required("Required"),
						})}
						onSubmit={(values) => {
							if (exist) {
								const submitted = {
									nama_kategori_perjal:
										values.nama_kategori_perjal,
									status_kategori_perjal:
										values.status_kategori_perjal,
								}

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									toast({
										title: "Gagal",
										description: "Data gagal diubah",
										status: "error",
										duration: 3000,
										isClosable: true,
									})
									console.log(error)
								}
							} else {
								const submitted = {
									nama_kategori_perjal:
										values.nama_kategori_perjal,
									status_kategori_perjal:
										values.status_kategori_perjal,
								}

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
						enableReinitialize={true}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInput
										id="nama_kategori_perjal"
										name="Nama Kategori Perjal"
										errors={errors.nama_kategori_perjal}
										touched={touched.nama_kategori_perjal}
									/>
									{/* <FormControl
										isInvalid={
											!!errors.status_kategori_perjal &&
											touched.status_kategori_perjal
										}
									>
										<FormLabel
											htmlFor="status_kategori_perjal"
											mt={2}
										>
											Status Kategori Perjal
										</FormLabel>
										<Select
											id="status_kategori_perjal"
											name="status_kategori_perjal"
											type="status_kategori_perjal"
											placeholder="Pilih Status Kategori Perjal"
											onChange={(e) => {
												setFieldValue(
													"status_kategori_perjal",
													e.target.value
												)
											}}
											value={
												values.status_kategori_perjal
											}
										>
											<option value={true}>Aktif</option>
											<option value={false}>
												Tidak Aktif
											</option>
										</Select>
									</FormControl> */}

									<FormInputSelectedAktif
										id="status_kategori_perjal"
										name="Status Kategori Perjal"
										errors={errors.status_kategori_perjal}
										touched={touched.status_kategori_perjal}
									/>
									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormKategoriPerjal
