import React from "react"
import { Flex, Text, Icon, Menu, MenuButton } from "@chakra-ui/react"

export default function NavItem({ icon, title, active, navSize, width }) {
	return (
		<Flex
			mt={2}
			flexDir="column"
			w="100%"
			alignContent={"center"}
			alignSelf={"center"}
			textAlign={"center"}
			justifyContent={"center"}
			alignItems={navSize == "large" ? "center" : "center"}
		>
			<Menu placement="right">
				<Flex
					backgroundColor={active && "var(--color-primer)"}
					p={2}
					borderRadius={10}
					_hover={
						active ? {} : { backgroundColor: "var(--color-hover)" }
					}
					w={navSize == "large" && width}
				>
					<MenuButton>
						<Flex alignItems={"center"}>
							<Icon
								fontWeight={"bold"}
								fontSize={"var(--icon-2)"}
								as={icon}
								color={
									active
										? "var(--color-on-primary)"
										: "var(--color-primer)"
								}
							/>
							<Text
								ml={5}
								display={navSize == "small" ? "none" : "flex"}
								fontWeight={"semibold"}
								fontSize={"var(--header-5)"}
								color={
									active
										? "var(--color-on-primary)"
										: "var(--color-primer)"
								}
								textAlign={"left"}
							>
								{title}
							</Text>
						</Flex>
					</MenuButton>
				</Flex>
			</Menu>
		</Flex>
	)
}
