import React from "react"
import { Flex, Box, Center, Text, Image } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux";
import { routePageName } from "../../Utils/Redux/action";
import SidebarMenu from "../SidebarMenu/sidebarmenu"
import { APP_NAME, APP_DESCRIPTION } from "../../Utils/config"

const SideBar = () => {
	const dispatch = useDispatch();

	const patchRoute = (data) => {
		dispatch(routePageName(data));
	};
	const { routeName } = useSelector((state) => state.userReducer);

	return (
		<Box
			h={"100%"}
			boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
			backgroundColor={"white"}
		>
			<Flex
				// backgroundColor={"white"}
				display={{
					base: "none",
					lg: "flex",
				}}
				pos="sticky"
				// h="100vh"

				// boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
				w={"30vh"}
				flexDir="column"
			>
				<Flex
					flexDir="column"
					w="100%"
					as="nav"
					// className="navbar"
				>
					<Box>
						<Center>
							<Flex
								mt={"8"}
								mb={"5"}
								textAlign={"center"}
								justifyContent={"center"}
								alignContent={"center"}
								flexDir={"column"}
							>
								<Box display="flex" justifyContent="center">
									<Image
										src={
											process.env.PUBLIC_URL +
											"/logox.png"
										}
										alt="Logo"
										loading="lazy"
										width="45%"
									/>
								</Box>

								<Text
									fontSize={"var(--header-1)"}
									fontWeight={"regular"}
									fontFamily={"var(--font-family-secondary)"}
									color={"#000000"}
								>
									{APP_NAME}
								</Text>
								<Text
									fontSize={"var(--header-4)"}
									fontFamily={"var(--font-family-tertiary)"}
									color={"#000000"}
									mx={"2"}
								>
									{APP_DESCRIPTION}
								</Text>
							</Flex>
						</Center>
					</Box>
					<SidebarMenu />
				</Flex>
			</Flex>
		</Box>
	)
}
export default SideBar
