import React from "react"
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useToast,
} from "@chakra-ui/react"
import axios from "axios"

const PopupDelete = ({
	isOpen,
	onClose,
	cancelRef,
	name,
	linkDelete,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<AlertDialog
			isCentered
			size={"sm"}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="xl" fontWeight="bold">
						Hapus Data {name}
					</AlertDialogHeader>

					<AlertDialogBody>
						Apakah anda yakin ingin menghapus data ini?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								try {
									axios
										.delete(linkDelete, {
											withCredentials: true,
										})
										.then((res) => {
											// console.log(res)
											toast({
												title: "Data Berhasil Dihapus",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
										})
										.catch((err) => {
											console.log(err)
										})
								} catch (error) {
									console.log(error)
								}
								onClose()
							}}
							ml={3}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}

export default PopupDelete
