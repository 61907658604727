import React from "react"
import { Flex, Text, Icon, Menu, Box, Spacer } from "@chakra-ui/react"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"

export default function Section({ icon, title, navSize, variant }) {
	return (
		<Flex
			mt={2}
			flexDir="column"
			w="100%"
			alignContent={"center"}
			alignSelf={"center"}
			// textAlign={"center"}
			justifyContent={"center"}
			alignItems={navSize == "large" ? "center" : "center"}
		>
			<Menu placement="right">
				<Flex p={2} borderRadius={10} w={navSize == "large" && "95%"}>
					<Box>
						<Flex alignItems={"center"}>
							<Icon
								fontWeight={"bold"}
								fontSize={"var(--icon-2)"}
								as={icon}
								color={"var(--color-primer)"}
							/>
							<Text
								ml={5}
								display={navSize == "small" ? "none" : "flex"}
								fontWeight={"semibold"}
								fontSize={"var(--header-5)"}
								color={"var(--color-primer)"}
							>
								{title}
							</Text>
						</Flex>
					</Box>
					<Spacer />
					<Box
						display={navSize == "small" ? "none" : "flex"}
						alignItems={"center"}
						justifyContent={"center"}
					>
						{variant == "up" ? (
							<Icon
								fontWeight={"bold"}
								fontSize={"var(--icon-2)"}
								as={BsChevronUp}
								color={"var(--color-primer)"}
							/>
						) : (
							<Icon
								fontWeight={"bold"}
								fontSize={"var(--icon-2)"}
								as={BsChevronDown}
								color={"var(--color-primer)"}
							/>
						)}
					</Box>
				</Flex>
			</Menu>
		</Flex>
	)
}
