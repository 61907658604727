import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"

import FormKabupaten from "./formkabupaten"
import { APIKabupaten } from "../../../../Components/Admin/Utils/API/api"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.nama_kabupaten
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.id_kabupaten
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.latitude.toLowerCase().includes(keyword.toLowerCase()) ||
				data.longitude.toLowerCase().includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const API = APIKabupaten
const name = "Kabupaten"
const Form = FormKabupaten

const PageKabupaten = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "Kode Kabupaten",
			selector: "id_kabupaten",
			sortable: true,
			width: "15%",
		},
		{
			name: "Nama Kabupaten",
			selector: "nama_kabupaten",
			sortable: true,
		},
		{
			name: "Latitude",
			selector: "latitude",
			sortable: true,
			width: "15%",
		},
		{
			name: "Longitude",
			selector: "longitude",
			sortable: true,
			width: "15%",
		},

		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					cell: (row) => (
						<>
							<Action
								id={row.id_kabupaten}
								nameSelected={row.nama_kabupaten}
								APIEdit={API.edit}
								APIDelete={API.delete}
								row={row}
								Edit={Form}
								name={name}
								fetchData={fetchData}
							/>
						</>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
				setLoading(false)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			progressPending={loading}
			fetchData={fetchData}
		/>
	)
}

export default PageKabupaten
