import React from "react"
import { Card, CardBody, Text, Box } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const AdminCard = ({ Icon, counter, name, link }) => {
	const navigate = useNavigate()

	return (
		<Card
			alignContent={"center"}
			justifyContent={"center"}
			_hover={{
				boxShadow: "0 0 0 1px var(--color-primer)",
				cursor: "pointer",
			}}
			onClick={() => {
				navigate(link)
			}}
		>
			<CardBody>
				<Box display={"flex"} justifyContent={"center"} mb={2}>
					<Icon
						fontWeight={"bold"}
						fontSize={"var(--font-size-max)"}
						as={Icon}
						color={"var(--color-primer)"}
					/>
				</Box>

				<Text
					mb={2}
					fontWeight={"bold"}
					fontSize={"var(--font-size-max2)"}
					color={"var(--color-primer)"}
					textAlign={"center"}
				>
					{counter}
				</Text>
				<Text
					fontWeight={"semibold"}
					fontSize={"var(--header-3)"}
					color={"var(--color-primer)"}
					textAlign={"center"}
				>
					{name}
				</Text>
			</CardBody>
		</Card>
	)
}

export default AdminCard
