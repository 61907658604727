import {
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	Image,
	useDisclosure,
	Flex,
} from "@chakra-ui/react"
import PopupImage from "../../../../Components/Admin/Table/Popup/Image/image"

export const ExpandDRK = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Box p={5}>
			<PopupImage
				isOpen={isOpen}
				onClose={onClose}
				props={props}
				header={"Ruas Jalan " + props.data.ruas_jalan.nama_ruas_jalan}
				image={props.data.url_foto_drk}
				alt={props.data.nama_ruas_jalan}
				caption={props.data.keterangan}
			/>
			<Table size={"sm"} variant={"simple"} colorScheme="blue">
				{/* <Thead>
					<Tr>
						<Th>Label</Th>
						<Th>Value</Th>
					</Tr>
				</Thead> */}
				<Tbody>
					<Tr>
						<Td fontWeight={"bold"}>Nomor DRK</Td>
						<Td>{props.data.id_drk}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Ruas Jalan</Td>
						<Td>{props.data.ruas_jalan.nama_ruas_jalan}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Jenis DRK</Td>
						<Td>{props.data.jenis_drk}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Latitude</Td>
						<Td>{props.data.latitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Longitude</Td>
						<Td>{props.data.longitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Foto</Td>
						<Td onClick={onOpen}>
							<Flex>
								<Image
									_hover={{ cursor: "pointer" }}
									src={props.data.url_foto_drk}
									alt={props.data.nama_ruas_jalan}
									width="240px"
								/>
							</Flex>
						</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Keterangan</Td>
						<Td>{props.data.keterangan}</Td>
					</Tr>
				</Tbody>
			</Table>
		</Box>
	)
}
