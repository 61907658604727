import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"

import FormKategoriPerjal from "./formkategoriperjal"
import { APIKategoriPerjal } from "../../../../Components/Admin/Utils/API/api"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return data.nama_kategori_perjal
				.toLowerCase()
				.includes(keyword.toLowerCase())
		})
		return results
	} else {
		return data
	}
}

const API = APIKategoriPerjal
const name = "Kategori Perjal"
const Form = FormKategoriPerjal

const PageKategoriPerjal = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_kategori_perjal",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Kategori Perjal",
			selector: "nama_kategori_perjal",
			sortable: true,
		},
		{
			name: "Status",
			selector: "status_kategori_perjal",
			sortable: true,
			width: "100px",
			cell: (row) => (
				<>
					{row.status_kategori_perjal == "1"
						? "Aktif"
						: "Tidak Aktif"}
				</>
			),
		},

		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_kategori_perjal}
							nameSelected={row.nama_kategori_perjal}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
				setLoading(false)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((response) => {
			setUser(response.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			fetchData={fetchData}
		/>
	)
}

export default PageKategoriPerjal
