import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Image } from "@chakra-ui/react"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"

import FormNamaPerjal from "./formnamaperjal"
import {
	APIKategoriPerjal,
	APINamaPerjal,
} from "../../../../Components/Admin/Utils/API/api"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.nama_perjal
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.kategori_perjal.nama_kategori_perjal
					.toLowerCase()
					.includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const API = APINamaPerjal
const API2 = APIKategoriPerjal
const name = "Nama Perjal"
const Form = FormNamaPerjal

const PageNamaPerjal = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()
	const [dataCategory, setDataCategory] = useState()
	const [loading, setLoading] = useState(true)

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_nama_perjal",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Nama Perjal",
			selector: "nama_perjal",
			sortable: true,
		},
		{
			name: "Kategori Perjal",
			selector: "kategori_perjal.nama_kategori_perjal",
			sortable: true,
		},
		{
			name: "Simbol",
			selector: "url_simbol_nama_perjal",
			sortable: true,
			width: "100px",
			cell: (row) => (
				<Image
					src={row.url_simbol_nama_perjal}
					alt={row.nama_perjal}
					width="50px"
					height="50px"
				/>
			),
		},
		{
			name: "Status",
			selector: "status_nama_perjal",
			sortable: true,
			width: "100px",
			cell: (row) => (
				<>{row.status_nama_perjal == "1" ? "Aktif" : "Tidak Aktif"}</>
			),
		},
		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_nama_perjal}
							nameSelected={row.nama_perjal}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							category={dataCategory}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
				setLoading(false)
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(API2.show).then((response) => {
				setDataCategory(response.data)
				setLoading(false)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((response) => {
			setUser(response.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			category={dataCategory}
			fetchData={fetchData}
		/>
	)
}

export default PageNamaPerjal
