import React, { useState } from "react"
import { Flex, Image, Box, Center, Text, Icon, calc } from "@chakra-ui/react"
import Navbar from "../Navbar/navbar"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { FaTwitter } from "react-icons/fa6"
import { MdFacebook } from "react-icons/md"
import { AiFillInstagram } from "react-icons/ai"
import { Link } from "react-router-dom"

const Board = () => {
	return (
		<Flex width="100%" overflowY="hidden">
			<Navbar overflow="hidden" />
			<Box flex="1" overflowY="hidden">
				<Outlet overflowY="hidden" />
				<Box
					borderTop={"solid 5px #fbcf00"}
					width={"100%"}
					bg={"#0A0D13"}
					height={{
						base: "90vh",
						sm: "80vh",
						md: "40vh",
						lg: "50vh",
					}}
					justifyContent={"center"}
					alignItems={"center"}
					className="footer"
					bgGradient={"linear(to-t, #243B55, #141E30)"}
					pt={"10vh"}
					pb={"10vh"}
					px={"5vw"}
				>
					<Flex
						flexDir={{ base: "column", md: "row" }}
						width={{ md: "90%" }}
					>
						<Flex
							flexDir={{ base: "column", md: "column" }}
							width={{ md: "70%" }}
						>
							<Text
								color={"#fbcf00"}
								fontSize={"24px"}
								fontWeight={"semibold"}
							>
								SiPANJA Provinsi Lampung
							</Text>
							<Text mb={"16px"} color={"white"}>
								Dishub Provinsi Lampung adalah Penyelenggaraan
								urusan pemerintah bidang kebijakan perhubungan
								atau transportasi untuk wilayah provinsi
								Lampung.
							</Text>
							<Flex width={"23%"} justify={"space-between"}>
								<Link to={"https://twitter.com/dishub_prov_lpg"}>
								<Flex
									alignItems={"center"}
									justifyContent={"center"}
									border={
										"1px solid rgba(255, 255, 255, 0.2)"
									}
									borderRadius={"100%"}
									padding={"5px"}
								>
									<FaTwitter
										color={"white"}
										size={"17px"}
										padding={"15px"}
									/>
								</Flex>
								</Link>
								<Link to={'https://www.instagram.com/dishubprovlampung/'} >
								<Flex
									alignItems={"center"}
									justifyContent={"center"}
									border={
										"1px solid rgba(255, 255, 255, 0.2)"
									}
									borderRadius={"100%"}
									padding={"5px"}
								>
									<AiFillInstagram
										color={"white"}
										size={"17px"}
										padding={"15px"}
										/>
								</Flex>
								</Link>
								<Link to={'https://www.facebook.com/Dishub.Lampung/'}>
								<Flex
									alignItems={"center"}
									justifyContent={"center"}
									border={
										"1px solid rgba(255, 255, 255, 0.2)"
									}
									borderRadius={"100%"}
									padding={"5px"}
								>
									<MdFacebook
										color={"white"}
										size={"17px"}
										padding={"15px"}
										/>
								</Flex>
								</Link>
							</Flex>
						</Flex>
						<Flex
							flexDir={"column"}
							justify={{ base: "center", md: "space-evenly" }}
							height={"20vh"}
							mx={{ base: "0%", md: "10%" }}
							width={{ md: "30%" }}
						>
							<Text
								color={"#fbcf00"}
								fontSize={"12px"}
								fontWeight={"semibold"}
							>
								Layanan Kami
							</Text>
							<Link to={"/kebutuhan_ruas"}>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Kebutuhan Ruas
								</Text>
							</Link>
							<Link to={"/ruas_terpasang"}>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Ruas Pemasangan
								</Text>
							</Link>
							<Link to={"/saran_aduan"}>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Saran dan Aduan
								</Text>
							</Link>
							<Link to={"/login"}>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Login
								</Text>
							</Link>
						</Flex>
						<Flex
							flexDir={"column"}
							justify={"space-evenly"}
							height={"20vh"}
							width={{ md: "30%" }}
						>
							<Flex>
								<Text
									color={"#fbcf00"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Alamat
								</Text>
							</Flex>
							<Flex flexDir={"column"}>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Jl.Cut Mutiah No. 76
								</Text>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Teluk Betung
								</Text>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									Bandar Lampung
								</Text>
							</Flex>
							<Flex>
								<Text
									color={"#fbcf00"}
									fontSize={"12px"}
									fontWeight={"bold"}
									letterSpacing={"1px"}
								>
									Phone:
								</Text>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									(0721) 470209
								</Text>
							</Flex>
							<Flex>
								<Text
									color={"#fbcf00"}
									fontSize={"12px"}
									fontWeight={"bold"}
									letterSpacing={"1px"}
								>
									Email:
								</Text>
								<Text
									color={"white"}
									fontSize={"12px"}
									fontWeight={"semibold"}
								>
									dishubprovlampung@gmail.com
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</Box>
				<Box
					width={"100%"}
					height={"fit-content"}
					justifyContent={"center"}
					bg={"#0e1d34"}
				>
					<Flex
						width={"100%"}
						paddingY={"10px"}
						justifyContent={"center"}
					>
						<Text
							fontSize={"14px"}
							textAlign={"center"}
							color={"white"}
						>
							©Copyright <strong>SiPANJA Provinsi Lampung</strong>
							. All rights reserved.
						</Text>
					</Flex>
				</Box>
			</Box>
		</Flex>
	)
}
export default Board
