import React from "react"
import * as Yup from "yup"
import {
	Flex,
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	FormLabel,
	Button,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import { FormInputSelected } from "../../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"
import { useToast } from "@chakra-ui/react"

const FormDRK = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	category,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										id_ruas_jalan:
											row.ruas_jalan.id_ruas_jalan,
										jenis_drk: row.jenis_drk,
										file_foto_drk: "",
										latitude: row.latitude,
										longitude: row.longitude,
										keterangan: row.keterangan,

										category: category,
								  }
								: {
										id_ruas_jalan: "",
										jenis_drk: "",
										file_foto_drk: "",
										latitude: "",
										longitude: "",
										keterangan: "",
										category: category,
								  }
						}
						validationSchema={Yup.object({
							id_ruas_jalan: Yup.string().required("Required"),
							jenis_drk: Yup.string().required("Required"),

							latitude: Yup.string().required("Required"),
							longitude: Yup.string().required("Required"),
							keterangan: Yup.string().required("Required"),
						})}
						onSubmit={(values) => {
							if (exist) {
								const formData = new FormData()
								formData.append(
									"id_ruas_jalan",
									values.id_ruas_jalan
								)
								formData.append("jenis_drk", values.jenis_drk)
								formData.append("latitude", values.latitude)
								formData.append("longitude", values.longitude)
								formData.append("keterangan", values.keterangan)
								formData.append(
									"file_foto_drk",
									values.file_foto_drk
								)

								// console.log(linkEdit)

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 200) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							} else {
								const formData = new FormData()
								formData.append(
									"id_ruas_jalan",
									values.id_ruas_jalan
								)
								formData.append("jenis_drk", values.jenis_drk)
								formData.append("latitude", values.latitude)
								formData.append("longitude", values.longitude)
								formData.append("keterangan", values.keterangan)
								formData.append(
									"file_foto_drk",
									values.file_foto_drk
								)

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInputSelected
										id="id_ruas_jalan"
										name="Ruas Jalan"
										errors={errors.id_ruas_jalan}
										touched={touched.id_ruas_jalan}
										options={
											values.category
												? values.category.map(
														(item) => {
															return {
																value: item.id_ruas_jalan,
																label: item.nama_ruas_jalan,
															}
														}
												  )
												: []
										}
										placeholder={"Pilih Ruas Jalan"}
									/>

									<FormInputSelected
										id="jenis_drk"
										name="Jenis DRK"
										errors={errors.jenis_drk}
										touched={touched.jenis_drk}
										options={[
											{ value: "Area", label: "Area" },
											{ value: "Poin", label: "Poin" },
										]}
										placeholder={"Pilih Jenis DRK"}
									/>

									<Flex mt={5}>
										<FormInput
											id="latitude"
											name="Latitude"
											errors={errors.latitude}
											touched={touched.latitude}
										/>
										<FormInput
											id="longitude"
											name="Longitude"
											errors={errors.longitude}
											touched={touched.longitude}
										/>
									</Flex>
									<Button
										size={"sm"}
										colorScheme="orange"
										width={"100%"}
										mb={5}
										variant={"outline"}
										onClick={() => {
											//get current position
											navigator.geolocation.getCurrentPosition(
												(position) => {
													setFieldValue(
														"latitude",
														position.coords.latitude
													)
													setFieldValue(
														"longitude",
														position.coords
															.longitude
													)
												}
											)
										}}
									>
										Generate Latitude & Longitude
									</Button>
									{/* {exist ? (
										<Image
											alignItems={"center"}
											justifyContent={"center"}
											src={values.file_foto_drk_lama}
											alt={values.nama_ruas_jalan}
											height="240px"
										/>
									) : null} */}

									<FormControl
										isInvalid={
											!!errors.file_foto_drk &&
											touched.file_foto_drk
										}
									>
										<FormLabel htmlFor="file_foto_drk">
											{exist
												? "Upload Foto Baru"
												: "Uplad Foto"}
										</FormLabel>
										<Input
											id="file_foto_drk"
											name="file_foto_drk"
											type="file"
											onChange={(event) => {
												setFieldValue(
													"file_foto_drk",
													event.currentTarget.files[0]
												)
											}}
											p={1}
											border={0}
										/>
									</FormControl>

									<FormInput
										id="keterangan"
										name="Keterangan"
										errors={errors.keterangan}
										touched={touched.keterangan}
									/>
									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormDRK
