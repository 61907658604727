import React, { useState, useEffect, useCallback } from "react"
import {
	Flex,
	Box,
	Text,
	Input,
	Image,
	Divider,
	InputGroup,
	FormErrorMessage,
	InputLeftAddon,
	Button,
	FormControl,
	FormLabel,
} from "@chakra-ui/react"
import Cookies from "js-cookie"
import { FaUser } from "react-icons/fa"
import { Link, Navigate } from "react-router-dom"
import { RiLockPasswordFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import * as yup from "yup"
import "./home.css"
import jwt_decode from "jwt-decode"
import axios from "axios"
import { loginApi } from "../../Components/Publik/Util/api"
import { TabTitle } from "../../Components/Admin/Utils/config"
import useAuth from "../../../src/middleware/useAuth"
import { token } from "../../Components/Publik/Util/api"
import { routePageName } from "../../../src/Components/Admin/Utils/Redux/action"
import { useDispatch } from "react-redux"

export const UserContext = React.createContext()
const schema = yup.object({
	username: yup.string().required("Username harus diisi"),
	password: yup.string().required("Password harus diisi"),
})
const Login = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const checkToken = async () => {
		try {
			await axios
				.get(token, {
					withCredentials: true,
				})
				.then((response) => {
					if (response.status == 200) {
						dispatch(routePageName("Dashboard"))
						navigate("/admin/dashboard")
					}
				})
		} catch (error) {
			console.error(error)
			navigate("/login")
			if (error.response && error.response.status === 401) {
				navigate("/login")
			} else {
				console.error("Terjadi kesalahan saat memeriksa token:", error)
			}
		}
	}

	TabTitle("Login")
	const [username, setUsername] = useState("")

	const imageUrl = `${process.env.PUBLIC_URL + "/background.png"}`
	const [msg, setMsg] = useState("")

	const login = async (username, password) => {
		try {
			await axios
				.post(
					loginApi,
					{
						username: username,
						password: password,
					},
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					navigate("/admin/dashboard")
				})
		} catch (error) {
			setMsg(error.response.data.msg)
		}
	}

	useEffect(() => {
		checkToken()
	}, [])

	return (
		<>
			<Box
				width={"100%"}
				display="flex"
				flexDirection="column"
				minHeight="100vh"
				bgImage={`url(${imageUrl})`}
				bgSize="cover"
				bgRepeat="no-repeat"
				justifyContent={"center"}
				alignItems={"center"}
				alignContent={"center"}
				bgPos={"center"}
				overflowY="hidden"
			>
				<Flex
					border={"1px solid #E2E8F0"}
					borderRadius={"10px"}
					boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
					zIndex={1}
					width={{ base: "85%", md: "40%", lg: "40%" }}
					height={{ base: "50vh", md: "40vh", lg: "58vh" }}
					bgColor={"white"}
					flexDirection={"column"}
				>
					<Flex
						alignItems={"center"}
						alignContent={"center"}
						justify={"center"}
						width={"100%"}
						height={"40%"}
						borderTopRadius={"10px"}
						borderTop={"4px solid #007bff"}
					>
						<Image
							paddingY={"10px"}
							h={{
								base: "100px",
								sm: "100px",
								md: "200px",
								lg: "120px",
							}}
							maxH={"100%"}
							src={process.env.PUBLIC_URL + "/logodishub.png"}
						/>
					</Flex>
					<Divider orientation="horizontal" />
					<Flex
						alignItems={"center"}
						height={"100%"}
						width={"100%"}
						justify={"center"}
					>
						<Flex
							pt={"12px"}
							height={{ base: "100%", md: "60%", lg: "100%" }}
							flexDir={"column"}
							width={"90%"}
							alignItems={"center"}
							justify={"space-evenly"}
							alignContent={"center"}
						>
							<Flex
								bg={"red"}
								borderRadius={"10px"}
								px={"10px"}
								justify={"center"}
							>
								<Text
									fontWeight={"semibold"}
									fontFamily={"sans-serif"}
									color={"white"}
								>
									{msg}
								</Text>
							</Flex>
							<Formik
								initialValues={{
									username: "",
									password: "",
								}}
								validationSchema={schema}
								onSubmit={(values) => {
									login(values.username, values.password)
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
								}) => (
									<Form onSubmit={handleSubmit}>
										<FormControl
											width={{
												base: "100%",
												md: "100%",
												lg: "380px",
											}}
											size={"md"}
											marginTop={"20px"}
											isInvalid={
												errors.username &&
												touched.username
											}
										>
											<InputGroup size="sm">
												<InputLeftAddon pointerEvents="none">
													<FaUser color="gray.300" />
												</InputLeftAddon>
												<Input
													type="text"
													value={values.username}
													onChange={handleChange}
													name="username"
													variant="outline"
													placeholder="Username"
												/>
											</InputGroup>
											<FormErrorMessage>
												{errors.username}
											</FormErrorMessage>
										</FormControl>
										<FormControl
											width={{
												base: "100%",
												md: "100%",
												lg: "380px",
											}}
											size={"md"}
											marginTop={"20px"}
											isInvalid={
												errors.password &&
												touched.password
											}
										>
											<InputGroup size="sm">
												<InputLeftAddon
													children={"none"}
													pointerEvents="none"
												>
													<RiLockPasswordFill color="gray.300" />
												</InputLeftAddon>
												<Input
													type="password"
													name="password"
													value={values.password}
													onChange={handleChange}
													variant="outline"
													placeholder="Password"
												/>
											</InputGroup>
											<FormErrorMessage>
												{errors.password}
											</FormErrorMessage>
										</FormControl>
										<Flex width={"100%"} mt={"5%"}>
											<Button
												type="submit"
												alignContent={"center"}
												justifyContent={"center"}
												alignItems={"center"}
												bgColor="#007bff"
												width={"100%"}
												size="sm"
											>
												<Flex
													mt={"5px"}
													alignContent={"center"}
													justifyContent={"center"}
													alignItems={"center"}
													width={"100%"}
												>
													<Text
														fontSize={"14px"}
														color="white"
													>
														Masuk
													</Text>
												</Flex>
											</Button>
										</Flex>
									</Form>
								)}
							</Formik>
						</Flex>
					</Flex>
				</Flex>
			</Box>
		</>
	)
}
export default Login
