import {React, useEffect, useState} from 'react';
import { Flex,Wrap,Box,Text,Image, Divider, Tbody, Td,Tr, Table } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import MapComponent from '../../Components/Publik/Map/map';
import { Link, NavLink, useParams } from 'react-router-dom';
import { APIPublic } from '../../Components/Admin/Utils/API/api';
import axios from 'axios';
import { TabTitle } from '../../Components/Admin/Utils/config';
const RuasKebutuhan = () => {
  TabTitle("Ruas Kebutuhan Jalan")
  const { id } = useParams()
  const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#0e1d34",
            color: "#ffffff",
        },
    },
  };
  const [data, setData] = useState('')
  const [dataKabupaten,setDataKabupaten] = useState('')
  const fetchData = async () =>{
    try {
      await axios.get(`${APIPublic.show}${id}`).then((res)=>{
        setData(res.data)
      })
    } catch (error) {
    }
  }
const [selectedRows, setSelectedRows] = useState([]);
const handleRowSelect = (id) => {
  if (selectedRows.includes(id)) {
    setSelectedRows((prevSelected) => prevSelected.filter((rowId) => rowId !== id));
  } else {
    setSelectedRows((prevSelected) => [...prevSelected, id]);
  }
};
  useEffect(()=>{
    fetchData()
  },[])

  return (
    <>
    <Box
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh" 
        overflowY="hidden">
          <Box bgGradient='linear(to-b, #E9FCFE, #FAE4ED)' 
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={'2%'}
        minHeight="100vh">
           <Flex flexDir={{'base':'column','md':'row'}}  justifyContent={'flex-start'} width={'90%'}>
          <Wrap display={{'base': 'flex'}} mt={{'base':'40%','md':'20%','lg':'12%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'80%','md':'90%'}}>
            <Text color={'#0e1d34'} mt={{'base':'35%','sm':'0%','md':'0%'}} fontFamily={'inter'} fontSize={{'base':'30px','md':'48px'}} fontWeight={'bold'}>
              Kebutuhan Ruas
            </Text>
            <Text display={{'base':'none','md':'flex'}} color={'#0e1d34'} fontFamily={'inter'} fontSize={'16px'}>
            Berikut merupakan ruas kebutuhan terkait perlengkapan jalan meliputi APIL, alat penerangan jalan, alat pengendali dan pengaman pengguna jalan, marka jalan dan rambu lalu lintas di Provinsi Lampung.
            </Text>
          </Wrap>
          <Flex ml={{'base':'0%','md':'10%'}} display={{'base': 'flex', }} justify={'center'} mt={{'base':'-80%','md':'20%','lg':'0%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'100%','md':'80%'}}>
            <Image height={'fit-content'} width={{'base':'70%','md':'80%'}} src={process.env.PUBLIC_URL + '/kebutuhan_perjal.png'} />
          </Flex>
          </Flex>
          <Flex width={'90%'} mt={{'base':'15%', 'md':'0%', 'lg':'1%'}}>
            <Box
              width={'100%'}
              display="flex"
              bgColor={'#0e1d34'}
              height={'100%'}
              flexDirection="column"
              borderRadius={'10px'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
              >
               <Flex justify={'center'} alignContent={'center'} alignItems={'center'} ml={'10px'} mt={'10px'} borderTopRadius={'10px'} bg='white' h={'5%'} width={{'base':'40%','md':'20%','lg':'14%'}}>
                <Text color={'#495057'} mt={'2%'} ml={'2%'} fontFamily={'sans-serif'} fontSize={{'base':'16px','md':'16px'}} fontWeight={'bold'}>
                  Jalan Provinsi
                </Text>
              </Flex>
               <Flex flexDir={{'base':'column','md':'row'}} width={'100%'} py={'1%'} height={'100%'} bgColor={'white'}>
                <Flex flexDir={{'base':'column','md':'column'}} width={{'base':'100%','md':'35%'}} mx={{'base':'0%','md':'2%'}}>
                <Table variant='simple'>
                <Tbody>
                  <Tr>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      Kabupaten
                    </Td>
                    <Td>
                      :
                    </Td>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      {data.kabupaten?.nama_kabupaten|| "tidak diketahui"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      Ruas Jalan
                    </Td>
                    <Td>
                      :
                    </Td>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      {data.nama_ruas_jalan}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      Kode Ruas
                    </Td>
                    <Td>
                      :
                    </Td>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      {id}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      Panjang(Km)
                    </Td>
                    <Td>
                      :
                    </Td>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      {data.panjang}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      Jenis
                    </Td>
                    <Td>
                      :
                    </Td>
                    <Td fontWeight={'semibold'} fontSize={'14px'}>
                      {data.status}
                    </Td>
                  </Tr>
                </Tbody>
                </Table>
                <Flex mt={4}>
                <Text fontSize={'20px'} fontFamily={'sans-serif'} fontWeight={'medium'}>Perlengkapan Jalan</Text>
                </Flex>
                <Divider my={'3%'} width={'99%'} border={'0.6px solid'} opacity={'0.25'}/>
                <Box 
                  maxWidth={"calc(100vw - 2rem)"}
                  width={"100%"}
                  height={"100%"}
                  margin={{
                    base: "0rem",
                    md: "0rem",
                  }}
                >
                <Box border={"1px solid grey"} borderRadius={'10px'}>
                {
                data.perjal_by_kategori && data.perjal_by_kategori.length > 0 ? (
                data.perjal_by_kategori.map((perjal, index, array) => {
                return (
                  <div key={index}>
                    <Flex mx={3} justifyContent="space-between" alignItems="center" my={2}>
                        <Flex>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(perjal.id_kategori_perjal)}
                          onChange={() => handleRowSelect(perjal.id_kategori_perjal)}
                        />
                        </Flex>
                      <Flex width={'100%'} justify={'flex-start'} alignContent={'flex-start'} alignItems="flex-start">
                        <Text textAlign={'left'} fontWeight="semibold" fontSize="14px" ml={2}>
                          {perjal.nama_kategori_perjal}
                        </Text>
                      </Flex>
                      <Flex justifyContent="center" alignItems="center" borderRadius="10px" py={0} px={2} bg="#1D76F2">
                        <Text color="white" fontWeight="semibold" fontSize="14px">
                          {perjal.count}
                        </Text>
                      </Flex>
                    </Flex>
                    {index < array.length - 1 ? <Divider mx={2} my={1} width="96%" border="0.9px solid gray" opacity="0.25" /> : null}
                  </div>
                );
                })
                ) : (
                <p>Tidak ada data yang tersedia.</p>
                )
                }
                </Box>
                </Box>
                <Flex mt={4}>
                <Text fontSize={'20px'} fontFamily={'sans-serif'} fontWeight={'medium'}>Daerah Rawan Kecelakaan</Text>
                </Flex>
                <Divider my={'3%'} width={'99%'} border={'0.6px solid'} opacity={'0.25'}/>
                <Box 
                  maxWidth={"calc(100vw - 2rem)"}
                  width={"100%"}
                  height={"100%"}
                  margin={{
                    base: "0rem",
                    md: "0rem",
                  }}
                >
                <Box border={"1px solid grey"} borderRadius={'10px'}>
                    <Flex mx={3} justifyContent="space-between" alignItems="center" my={2}>
                        <Flex>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes()}
                          onChange={() => handleRowSelect()}
                        />
                        </Flex>
                      <Flex width={'100%'} justify={'flex-start'} alignContent={'flex-start'} alignItems="flex-start">
                        <Text textAlign={'left'} fontWeight="semibold" fontSize="14px" ml={2}>
                          TITIK RAWAN KECELAKAAN
                        </Text>
                      </Flex>
                      <Flex justifyContent="center" alignItems="center" borderRadius="10px" py={0} px={2} bg="#1D76F2">
                        <Text color="white" fontWeight="semibold" fontSize="14px">
                          {data.jumlah_drk_total}
                        </Text>
                      </Flex>
                    </Flex>
                </Box>
                </Box>
                </Flex >
                <Flex p={'10px'} width={'100%'}  alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                  <MapComponent 
                   from={'perjal'}
                   data={data}
                   popup={'Perjal : '}
                   selectedRows={selectedRows}
                  />
                </Flex>
              </Flex>  
              </Box>
          </Flex>  
          </Box>
    </Box>
    </>
  );
}
export default RuasKebutuhan;