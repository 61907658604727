import React, { useEffect, useState, useRef } from "react"
import { Box, Text } from "@chakra-ui/layout"
import {
	Input,
	Button,
	Icon,
	FormControl,
	Flex,
	InputGroup,
	InputLeftElement,
	useDisclosure,
	Select,
	FormLabel,
	ButtonGroup,
} from "@chakra-ui/react"

import { Link } from "react-router-dom"
import { AiOutlinePlus } from "react-icons/ai"
import { BsSearch } from "react-icons/bs"
import { IoChevronBack } from "react-icons/io5"

import XLSX from "xlsx"
import { useReactToPrint } from "react-to-print"

import DataTable, { ExpanderComponentProps } from "react-data-table-component"
import ExportPDF from "../Export/export-pdf"

const TableContent = ({
	user,
	Create,
	name,
	records,
	columns,
	filter,
	linkCreate,
	expand,
	ComponentExpanded,
	category,
	saran,
	dropdown,
	dropdownCategory1,
	dropdownCategory2,
	nameCategory1,
	nameCategory2,
	loading,
	fetchData,
	HandlerFilterDropDown,
}) => {
	const [filtered, setFiltered] = useState([])
	const [pdfViewer, setPdfViewer] = useState(true)
	// console.log(records)
	// console.log(filtered)
	const componentPDF = useRef()

	const createModal = useDisclosure()
	const cancelRef = React.useRef()

	const [dataRuas, setDataRuas] = useState("")
	const [dataKategori, setDataKategori] = useState("")

	const HandlerFilter = (e) => {
		const results = filter(records, e)
		setFiltered(results)
	}

	const CategoryFilter = (records, cat1, cat2) => {
		const results = HandlerFilterDropDown(records, cat1, cat2)
		setFiltered(results)
	}

	useEffect(() => {
		setFiltered(records)
	}, [records, filter, dropdownCategory1, dropdownCategory2])

	const ExpandedComponent = ({ data }) => {
		return (
			<>
				<ComponentExpanded data={data} />
			</>
		)
	}

	const handleExportXLSX = () => {
		const ws = XLSX.utils.json_to_sheet(filtered)
		const wb = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(wb, ws, `Data ${name}.xlsx`)
		XLSX.writeFile(wb, `Data ${name}.xlsx`)
	}
	const handleExportCSV = () => {
		const ws = XLSX.utils.json_to_sheet(filtered)
		const wb = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(wb, ws, `Data ${name}.csv`)
		XLSX.writeFile(wb, `Data ${name}.csv`)
	}

	const handleExportPDF = useReactToPrint({
		content: () => componentPDF.current,
		documentTitle: `Data ${name}`,
	})

	return (
		<>
			{pdfViewer ? (
				<>
					<Box
						mt={5}
						mb={10}
						display="flex"
						justifyContent="space-between"
						flexDirection={["column", "column", "row", "row"]}
						height={{
							// base: "10%",
							md: "5%",
						}}
					>
						<Flex
							flexDirection={["column", "column", "row", "row"]}
							width={"100%"}
							height={"100%"}
							justifyContent="space-between"
							alignContent={"center"}
						>
							{/* <Text color={"black"}>{user}</Text> */}
							{(!saran && user == "super admin") ||
							(!saran &&
								user == "admin" &&
								(name == "Perjal Terpasang" ||
									name == "Perjal Prioritas" ||
									name == "Perjal Kebutuhan")) ? (
								<Box
									alignItems={"center"}
									justifyContent={"center"}
									display={"flex"}
									mb={{
										base: 5,
										md: 0,
									}}
								>
									<Button
										size="sm"
										as={Link}
										colorScheme="teal"
										width={{
											base: "80%",
											md: "100%",
										}}
										leftIcon={
											<Icon
												as={AiOutlinePlus}
												color={"white"}
												fontWeight={"extrabold"}
												fontSize={"xl"}
											/>
										}
										onClick={createModal.onOpen}
									>
										<Text
											color={"white"}
											fontWeight={"medium"}
											fontSize={"sm"}
										>
											Tambah {name}
										</Text>
									</Button>
								</Box>
							) : null}

							<Create
								user={user}
								isOpen={createModal.isOpen}
								onClose={createModal.onClose}
								cancelRef={cancelRef}
								linkCreate={linkCreate}
								name={name}
								category={category}
								fetchData={fetchData}
							/>

							<Box
								width={{
									base: "100%",
									md: "30%",
								}}
								alignItems={"center"}
								justifyContent={"center"}
								display={"flex"}
							>
								<FormControl
									flexDirection={[
										"column",
										"column",
										"row",
										"row",
									]}
									width={{
										base: "80%",
										md: "100%",
									}}
									height={"100%"}
								>
									<InputGroup size={"sm"}>
										<InputLeftElement
											pointerEvents={"none"}
										>
											<Icon
												color="gray.300"
												fontSize="1.2em"
												size={"sm"}
												as={BsSearch}
											/>
										</InputLeftElement>
										<Input
											type="text"
											placeholder={`Cari ${name}`}
											size={"sm"}
											color={"black"}
											onChange={HandlerFilter}
											borderRadius={"lg"}
										/>
									</InputGroup>
								</FormControl>
							</Box>
						</Flex>
					</Box>

					<Flex
						justifyContent={{
							base: "center",
							md: "center",
							xl: "flex-end",
						}}
						mt={5}
					>
						<ButtonGroup spacing={2}>
							<Button
								onClick={handleExportXLSX}
								size={"sm"}
								variant="outline"
								colorScheme="green"
							>
								Export XLSX
							</Button>
							<Button
								onClick={handleExportCSV}
								size={"sm"}
								variant="outline"
								colorScheme="green"
							>
								Export CSV
							</Button>
							<Button
								onClick={() => {
									setPdfViewer(false)
								}}
								size={"sm"}
								variant="outline"
								colorScheme="red"
							>
								Export PDF
							</Button>
						</ButtonGroup>
					</Flex>

					{dropdown ? (
						<Flex
							flexDirection={["column", "column", "row", "row"]}
							width={"100%"}
							justifyContent="space-between"
							alignContent={"center"}
						>
							<FormControl
								flexDirection={[
									"column",
									"column",
									"row",
									"row",
								]}
								width={{
									base: "100%",
								}}
								height={"100%"}
							>
								<FormLabel
									fontWeight={"bold"}
									fontSize={"sm"}
									mb={{
										base: 1,
									}}
									color={"blackAlpha.700"}
									mx={{
										base: 5,
										md: 0,
									}}
								>
									Filter
								</FormLabel>
								<Box
									display={{
										base: "row",
										md: "flex",
									}}
									mx={{
										base: 5,
										md: 0,
									}}
								>
									<InputGroup size={"sm"}>
										<Select
											placeholder={
												"Pilih " + nameCategory1
											}
											size={"sm"}
											color={"black"}
											onChange={(e) => {
												setDataRuas(e.target.value)
												CategoryFilter(
													records,
													e.target.value,
													dataKategori
												)
											}}
											borderRadius={"lg"}
										>
											{dropdownCategory1.map(
												(item, index) => (
													<option
														key={index}
														value={item.value}
													>
														{item.label}
													</option>
												)
											)}
										</Select>
									</InputGroup>
									<InputGroup size={"sm"}>
										<Select
											placeholder={
												"Pilih " + nameCategory2
											}
											size={"sm"}
											color={"black"}
											onChange={(e) => {
												setDataKategori(e.target.value)
												CategoryFilter(
													records,
													dataRuas,
													e.target.value
												)
											}}
											borderRadius={"lg"}
										>
											{dropdownCategory2.map(
												(item, index) => (
													<option
														key={index}
														value={item.value}
													>
														{item.label}
													</option>
												)
											)}
										</Select>
									</InputGroup>
								</Box>
							</FormControl>
						</Flex>
					) : null}

					<Box
						maxWidth={"calc(100vw - 2rem)"}
						width={"100%"}
						margin={{
							base: "1rem",
							md: "0rem",
						}}
						ref={componentPDF}
					>
						<DataTable
							columns={columns}
							data={filtered}
							pagination
							highlightOnHover
							striped
							responsive
							paginationPerPage={10}
							paginationRowsPerPageOptions={[
								10, 15, 20, 25, 50, 100, 500, 1000,
							]}
							fixedHeader
							expandableRows={expand}
							expandableRowsComponent={ExpandedComponent}
							progressPending={loading}
						/>
					</Box>
				</>
			) : (
				<>
					<Flex
						justifyContent={"space-between"}
						mt={5}
						mb={10}
						mx={5}
					>
						<Button
							onClick={() => {
								setPdfViewer(true)
							}}
							variant={"ghost"}
						>
							<Icon
								as={IoChevronBack}
								fontWeight={"bold"}
								fontSize={"var(--icon-1)"}
								color={"var(--color-primer)"}
							/>
						</Button>

						<Button
							onClick={handleExportPDF}
							size={"sm"}
							variant="outline"
							colorScheme="red"
						>
							Export PDF
						</Button>
					</Flex>
					<Box ref={componentPDF}>
						<Text
							mt={10}
							mb={5}
							color={"black"}
							fontSize={"var(--header-3)"}
							fontWeight={"semibold"}
							textAlign={"center"}
						>
							Data {name}
						</Text>
						<Box
							maxWidth={"calc(100vw - 2rem)"}
							width={"100%"}
							margin={{
								base: "1rem",
								md: "0rem",
							}}
						>
							<DataTable
								columns={columns.slice(0, -1)}
								data={filtered}
								highlightOnHover
								striped
								responsive
							/>
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default TableContent
