import React from "react"
import { Box, Button, Icon, useDisclosure } from "@chakra-ui/react"
import PopupDelete from "../Popup/Delete/delete"

import { AiFillDelete, AiFillEdit } from "react-icons/ai"
import { BsChatDots } from "react-icons/bs"

const Action = ({
	user,
	id,
	row,
	Edit,
	APIDelete,
	APIEdit,
	nameSelected,
	name,
	category,
	aduan,
	fetchData,
}) => {
	const deleteModal = useDisclosure()
	const editModal = useDisclosure()

	const cancelRef = React.useRef()
	const cancelRef2 = React.useRef()

	const linkDelete = APIDelete + id
	const linkEdit = APIEdit + id

	return (
		<>
			<Box
				display="flex"
				justifyContent="space-around"
				width={{
					base: "100%",
				}}
				position={{
					base: "relative",
					md: "relative",
					lg: "relative",
					xl: "absolute",
				}}
			>
				{aduan ? (
					<Button
						colorScheme="yellow"
						size="sm"
						onClick={editModal.onOpen}
						leftIcon={
							<Icon
								as={BsChatDots}
								fontWeight={"extrabold"}
								fontSize={"md"}
							/>
						}
					>
						Tanggapi
					</Button>
				) : (
					<Button
						colorScheme="yellow"
						size="sm"
						onClick={editModal.onOpen}
						leftIcon={
							<Icon
								as={AiFillEdit}
								fontWeight={"extrabold"}
								fontSize={"md"}
							/>
						}
					>
						Edit
					</Button>
				)}

				<Button
					colorScheme="red"
					size="sm"
					onClick={deleteModal.onOpen}
					ml={2}
					leftIcon={
						<Icon
							as={AiFillDelete}
							fontWeight={"extrabold"}
							fontSize={"md"}
						/>
					}
				>
					Delete
				</Button>
			</Box>

			<Edit
				user={user}
				exist={true}
				isOpen={editModal.isOpen}
				onClose={editModal.onClose}
				cancelRef={cancelRef2}
				row={row}
				linkEdit={linkEdit}
				name={name}
				category={category}
				fetchData={fetchData}
			/>
			<PopupDelete
				isOpen={deleteModal.isOpen}
				onClose={deleteModal.onClose}
				cancelRef={cancelRef}
				id={id}
				name={name + " " + nameSelected}
				linkDelete={linkDelete}
				fetchData={fetchData}
			/>
		</>
	)
}

export default Action
