import {React, useEffect, useState} from 'react';
import { Flex,Wrap,Box,Text,Image, Divider } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import MapComponent from '../../Components/Publik/Map/map';
import { Link, NavLink, useParams } from 'react-router-dom';
import { APIRuasJalan, APIKabupaten } from '../../Components/Admin/Utils/API/api';
import axios from 'axios';
import './kabupaten_ruas.css'
import { TabTitle } from '../../Components/Admin/Utils/config';
const KabupatenPublikRuas = () => {
  TabTitle("Kabupaten")
  const { id } = useParams()
  const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#0e1d34",
            color: "#ffffff",
        },
    },
  };
  const Columns = [
    {
      name: "#",
      selector: "id_ruas_jalan",
      sortable: true,
      width: "20%",
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>
          <Text fontSize="13px">{row.id_ruas_jalan}</Text>
        </div>
      ),
    },
    {
      name: "Nama Ruas Jalan",
      selector: "nama_ruas_jalan",
      sortable: true,
      width: "50%",
      cell: (row) => (
        <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>
          <Link className="text" to={`ruas/${row.id_ruas_jalan}`}>
            <Text fontSize="13px">{row.nama_ruas_jalan}</Text>
          </Link>
        </div>
      ),
    },
    {
      name: "Pilih",
      selector: "isSelected",
      sortable: true,
      cell: (row) => (
        <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
          <input
            type="checkbox"
            checked={row.isSelected}
            onChange={() => handleCheckboxChange(row)}
          />
        </Flex>
      ),
    },
  ];
  const handleCheckboxChange = row => {
    const updatedRows = ruasJalan.map(ruas => {
      if (ruas.id_ruas_jalan === row.id_ruas_jalan) {
        return { ...ruas, isSelected: !ruas.isSelected };
      }
      return ruas;
    });
    setRuasJalan(updatedRows);
  
    const updatedSelectedRuas = updatedRows.filter(ruas => ruas.isSelected);
    setSelectedRuas(updatedSelectedRuas);
  };
  const [selectedRuas, setSelectedRuas] = useState([]);
  const [ruasJalan, setRuasJalan] = useState([])
  const fetchData = async () => {
  try {
    const response = await axios.get(`${APIRuasJalan.showApiByKabupaten}${id}`);
    const ruasDataWithSelection = response.data.ruasJalan.map(ruas => ({
      ...ruas,
      id_ruas_jalan: ruas.id_ruas_jalan,
      nama_ruas_jalan: ruas.nama_ruas_jalan,
      isSelected: false // Tambahkan isSelected: false di sini
    }));
    setRuasJalan(ruasDataWithSelection);
  } catch (error) {
    console.log(error);
  }
}
const [dataKabupaten,setDataKabupaten] = useState('')
  const fetchDataKabupaten = async () => {
    try {
        await axios (`${APIKabupaten.showDetail}${id}`).then((res) => {
        setDataKabupaten(res.data.nama_kabupaten);
        });
  }
  catch (error) {
    console.log(error);
  }
}
  useEffect(()=>{
    fetchData()
    fetchDataKabupaten()
  },[])

  return (
    <>
    <Box
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh" 
        overflowY="hidden">
          <Box bgGradient='linear(to-b, #E9FCFE, #FAE4ED)' 
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={'2%'}
        minHeight="100vh">
           <Flex flexDir={{'base':'column','md':'row'}}  justifyContent={'flex-start'} width={'90%'}>
          <Wrap display={{'base': 'flex'}} mt={{'base':'40%','md':'20%','lg':'12%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'80%','md':'90%'}}>
            <Text color={'#0e1d34'} mt={{'base':'35%','sm':'0%','md':'0%'}} fontFamily={'inter'} fontSize={{'base':'30px','md':'48px'}} fontWeight={'bold'}>
              Kebutuhan Ruas
            </Text>
            <Text display={{'base':'none','md':'flex'}} color={'#0e1d34'} fontFamily={'inter'} fontSize={'16px'}>
            Berikut merupakan ruas kebutuhan terkait perlengkapan jalan meliputi APIL, alat penerangan jalan, alat pengendali dan pengaman pengguna jalan, marka jalan dan rambu lalu lintas di Provinsi Lampung.
            </Text>
          </Wrap>
          <Flex ml={{'base':'0%','md':'10%'}} display={{'base': 'flex', }} justify={'center'} mt={{'base':'-80%','md':'20%','lg':'0%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'100%','md':'80%'}}>
            <Image height={'fit-content'} width={{'base':'70%','md':'80%'}} src={process.env.PUBLIC_URL + '/kebutuhan_perjal.png'} />
          </Flex>
          </Flex>
          <Flex width={'90%'} mt={{'base':'15%', 'md':'0%', 'lg':'1%'}}>
            <Box
              width={'100%'}
              display="flex"
              bgColor={'#0e1d34'}
              height={'100%'}
              flexDirection="column"
              borderRadius={'10px'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
              >
               <Flex justify={'center'} alignContent={'center'} alignItems={'center'} ml={'10px'} mt={'10px'} borderTopRadius={'10px'} bg='white' h={'5%'} width={{'base':'40%','md':'20%','lg':'14%'}}>
                <Text color={'#495057'} mt={'2%'} ml={'2%'} fontFamily={'sans-serif'} fontSize={{'base':'16px','md':'16px'}} fontWeight={'bold'}>
                  Jalan Provinsi
                </Text>
              </Flex>
               <Flex flexDir={{'base':'column','md':'row'}} width={'100%'} py={'1%'} height={'100%'} bgColor={'white'}>
                <Flex flexDir={{'base':'column','md':'column'}} width={{'base':'100%','md':'35%'}} mx={{'base':'0%','md':'2%'}}>
                <Text color={'#495057'} mx='10px' fontSize={'16px'} fontWeight={'semibold'}>
                  Kabupaten/Kota : {dataKabupaten}
                </Text>
                <Text color={'#495057'} mx='10px' fontSize={'16px'} fontWeight={'semibold'}>
                  Ruas Jalan
                </Text>
                <Divider my={'3%'} width={'100%'} border={'0.1px solid'} opacity={'0.25'}/>
                <Box 
                  maxWidth={"calc(100vw - 2rem)"}
                  width={"100%"}
                  height={"100%"}
                >
                 <DataTable 
              columns={Columns}
              data={ruasJalan}
              pagination
              customStyles={customStyles}
              striped
              paginationPerPage={10}
              paginationComponentOptions={{
                rowsPerPageText: 'Baris per halaman:',
                rangeSeparatorText: 'dari',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'Semua'
              }}
              
              noDataComponent={<Text>Tidak ada data</Text>}
              style={{ overflowX: 'auto' }}
              />
                </Box>
                </Flex >
                <Flex p={'10px'} width={'100%'}  alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                  <MapComponent 
                   from={'ruas'}
                   data={selectedRuas}
                   popup={'Ruas Jalan : '}
                  />
                </Flex>
              </Flex>  
              </Box>
          </Flex>  
          </Box>
    </Box>
    </>
  );
}
export default KabupatenPublikRuas;