import React, { useEffect } from "react"
import {
	Drawer,
	Flex,
	Box,
	Center,
	Text,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react"

import SidebarMenu from "../SidebarMenu/sidebarmenu"
import { APP_NAME, APP_DESCRIPTION } from "../../Utils/config"

const Draw = (props) => {
	const data = props.data

	useEffect(() => {}, [])

	return (
		<Drawer
			placement={"left"}
			onClose={data.onclose}
			isOpen={data.isopen}
			size={"xs"}
		>
			<DrawerOverlay />
			<DrawerContent p={"0"}>
				<DrawerCloseButton />
				<DrawerHeader
					borderBottomWidth="1px"
					color={"var(--color-primer)"}
				>
					<Box>
						<Center>
							<Flex
								mt={"3"}
								textAlign={"center"}
								flexDir={"column"}
							>
								<Text
									fontSize={"var(--header-2)"}
									fontWeight={"medium"}
									fontFamily={"var(--font-family-secondary)"}
									color={"#000000"}
								>
									{APP_NAME}
								</Text>
								<Text
									fontSize={"var(--header-4)"}
									fontWeight={"light"}
									fontFamily={"var(--font-family-tertiary)"}
									color={"#000000"}
								>
									{APP_DESCRIPTION}
								</Text>
							</Flex>
						</Center>
					</Box>
				</DrawerHeader>
				<DrawerBody p={"0"}>
					<SidebarMenu />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	)
}
export default Draw
