import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	FormLabel,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import {
	FormInputSelected,
	FormInputSelectedAktif,
} from "../../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"
import { useToast } from "@chakra-ui/react"

const FormNamaPerjal = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	category,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										nama_perjal: row.nama_perjal,
										id_kategori_perjal:
											row.kategori_perjal
												.id_kategori_perjal,
										foto_simbol: "",
										status_nama_perjal:
											row.status_nama_perjal,
										url_simbol_nama_perjal:
											row.url_simbol_nama_perjal,
										category: category,
								  }
								: {
										nama_perjal: "",
										id_kategori_perjal: "",
										foto_simbol: "",
										status_nama_perjal: "",
										url_simbol_nama_perjal: "",
										category: category,
								  }
						}
						validationSchema={Yup.object({
							nama_perjal: Yup.string().required(
								"Nama Perjal harus diisi"
							),
							id_kategori_perjal: Yup.string().required(
								"Kategori Perjal harus diisi"
							),
							status_nama_perjal: Yup.string().required(
								"Status Nama Perjal harus diisi"
							),
						})}
						onSubmit={(values) => {
							if (exist) {
								const formData = new FormData()
								formData.append(
									"nama_perjal",
									values.nama_perjal
								)
								formData.append(
									"id_kategori_perjal",
									values.id_kategori_perjal
								)
								formData.append(
									"status_nama_perjal",
									values.status_nama_perjal
								)
								formData.append(
									"file_simbol_nama_perjal",
									values.foto_simbol
								)
								formData.append(
									"id_kategori_perjal",
									values.id_kategori_perjal
								)

								// console.log(linkEdit)

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 200) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							} else {
								const formData = new FormData()
								formData.append(
									"nama_perjal",
									values.nama_perjal
								)
								formData.append(
									"id_kategori_perjal",
									values.id_kategori_perjal
								)
								formData.append(
									"status_nama_perjal",
									values.status_nama_perjal
								)
								formData.append(
									"file_simbol_nama_perjal",
									values.foto_simbol
								)
								formData.append(
									"id_kategori_perjal",
									values.id_kategori_perjal
								)

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInput
										id="nama_perjal"
										name="Nama Perjal"
										errors={errors.nama_perjal}
										touched={touched.nama_perjal}
									/>

									{/* <FormControl
										isInvalid={
											!!errors.id_kategori_perjal &&
											touched.id_kategori_perjal
										}
									>
										<FormLabel htmlFor="id_kategori_perjal">
											Kategori Perjal
										</FormLabel>
										<Select
											id="id_kategori_perjal"
											name="id_kategori_perjal"
											type="id_kategori_perjal"
											placeholder="Pilih Kategori Perjal"
											onChange={(event) => {
												setFieldValue(
													"id_kategori_perjal",
													event.currentTarget.value
												)
											}}
											value={values.id_kategori_perjal}
										>
											{values.category
												? values.category.map(
														(item) => {
															return (
																<option
																	value={item.id_kategori_perjal.toString()}
																>
																	{
																		item.nama_kategori_perjal
																	}
																</option>
															)
														}
												  )
												: []}
										</Select>
									</FormControl> */}

									<FormInputSelected
										id="id_kategori_perjal"
										name="Kategori Perjal"
										errors={errors.id_kategori_perjal}
										touched={touched.id_kategori_perjal}
										options={
											values.category
												? values.category.map(
														(item) => {
															return {
																value: item.id_kategori_perjal,
																label: item.nama_kategori_perjal,
															}
														}
												  )
												: []
										}
										placeholder="Pilih Kategori Perjal"
									/>

									<FormControl
										isInvalid={
											!!errors.foto_simbol &&
											touched.foto_simbol
										}
									>
										<FormLabel htmlFor="foto_simbol">
											{exist
												? "Upload Foto Baru"
												: "Uplad Foto"}
										</FormLabel>
										<Input
											id="foto_simbol"
											name="foto_simbol"
											type="file"
											onChange={(event) => {
												setFieldValue(
													"foto_simbol",
													event.currentTarget.files[0]
												)
											}}
											p={1}
											border={0}
										/>
									</FormControl>

									<FormInputSelectedAktif
										id="status_nama_perjal"
										name="Status Nama Perjal"
										errors={errors.status_nama_perjal}
										touched={touched.status_nama_perjal}
									/>
									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormNamaPerjal
